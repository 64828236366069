<template>
        <div class="col-md-12 col-12 my-3">
            <div class="border-color p-4" style="border-radius: 20px;">
                <h5 class="text-color unitamount-title">Default amounts</h5>
                <div class="row">
                    <div class="col-8">
                        <div class="form-group">
                            <label>Finance head</label>
                            <input class="form-control" type="text" name="financeHead" id="financeHead">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label>Amount</label>
                            <input class="form-control" type="text" name="amount" id="amount">
                        </div>
                    </div>
                    </div>
                    <div class="w-100 text-center my-3">
                        <router-link to="" class="btn btn-blue-color text-white" style="border-radius: 10px;">Create task for missing heads</router-link>
                    </div>
            </div>
        </div>
        <div class="text-center mt-4">
             <router-link to="/admin/viewamount" class="btn btn-color text-white" style="border-radius: 10px;">View amounts by location</router-link>
        </div>
</template>