<template>
    <div class="page-content w-100">
       <!-- start heading -->
   <div class="row">
  <div class="col-sm-12">
     <p class="text-center page-heading">Finance manager</p>
   </div>

 </div>
    <!-- end heading -->
  <div class="row mt-5">
  <div class="col-sm-12 mx-auto div-fix">

      <!-- start accordion -->
         <div class="accordion box-shadow" id="accordionFinance">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFinanceHead" aria-expanded="true" aria-controls="collapseFinanceHead">
        <strong>Finance heads</strong>
      </button>
    </h2>
    <div id="collapseFinanceHead" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFinance">
      <div class="accordion-body p-4">
        <FinanceHead/>
      </div>
    </div>
  </div>
  <!-- end first -->
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUnitAmount" aria-expanded="false" aria-controls="collapseUnitAmount">
       <strong>Unit amounts</strong>
      </button>
    </h2>
    <div id="collapseUnitAmount" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFinance">
      <div class="accordion-body p-4">
          <UnitAmount/>
      </div>
    </div>
  </div>
  <!-- end second -->
</div>

      <!-- end accordion -->

  </div>
  </div>
 </div>

</template>

<script>
import UnitAmount from './unit-amount.vue';
import FinanceHead from './finance-heads.vue';
export default{
    components:{
        UnitAmount,
        FinanceHead,
    }
}
</script>
<style>
  .col-sm-12.mx-auto.div-fix{
    margin-bottom: 30px;
  }
</style>