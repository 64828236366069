<template>
  <div class="row my-2">
    <div class="col-6">
      <label class="text-color">Name</label>
      <input type="text" class="form-control"/>
    </div>
    <div class="col-4">
      <label class="text-color">Category</label>
      <input type="text" class="form-control"/>
    </div>
    <div class="col-2 mt-3">
      <button type="button" @click="financeHeadForm = true;" style="font-size: 20px" id="btn_schedule_add" class="btn btn_edit">
        <font-awesome-icon :icon="['fas', 'edit']" />
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
      <input type="text" class="form-control" />
    </div>
    <div class="col-4">
      <input type="text" class="form-control" />
    </div>
    <div class="col-2">
      <button type="button" @click="financeHeadForm = true;" style="font-size: 20px" id="btn_schedule_add" class="btn btn_edit">
        <font-awesome-icon :icon="['fas', 'edit']" />
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
      <input type="text" class="form-control" />
    </div>
    <div class="col-4">
      <input type="text" class="form-control" />
    </div>
    <div class="col-2">
      <button type="button" @click="financeHeadForm = true;" style="font-size: 20px" id="btn_schedule_add" class="btn btn_edit">
        <font-awesome-icon :icon="['fas', 'edit']" />
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-10 mt-3">
      <hr class="hr-border" />
    </div>
    <div class="col-2">
      <button type="button" @click="financeHeadForm = true;" style="font-size: 30px" id="btn_schedule_add" class="btn btn_add">
        <font-awesome-icon :icon="['fas', 'plus-circle']" />
      </button>
    </div>
  </div>
  <div class="" v-if="financeHeadForm==true">
    <div class="row mt-3">
      <div class="col-6">
        <label class="text-color">Name</label>
        <input type="text" class="form-control"/>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <label class="text-color">Description</label>
        <textarea class="form-control" rows="3"></textarea>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <label class="text-color">Category</label>
        <select class="form-control">
            <option value="">Select payment / receipt</option>
            <option value="payment">Payment</option>
            <option value="receipt">Receipt</option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
            <label class="text-color">Select date</label>
            <input type="date" class="form-control"/>
        </div>
      <div class="col-6">
          <div class="float-right">
           <button type="button" class="btn btn-blue-color text-white mt-4">Add / Delete</button>
         </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default{
    data(){
        return{
            financeHeadForm:false,
        }
    },
}

</script>